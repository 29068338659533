import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="signup"
export default class extends Controller {
  static targets = ["signupModal", "phoneNumber", "hiddenPhoneNumber"]

  openModal() {
    this.signupModalTarget.classList.add("modal-open")

    // We want to register the phone number that was submitted as part
    // of the signup form
    this.hiddenPhoneNumberTarget.value = this.phoneNumberTarget.value
  }

  closeModal() {
    this.signupModalTarget.classList.remove("modal-open")
  }
}
