import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="home"
export default class extends Controller {
  static targets = ["modal", "searchForm"]

  initialize() {
    this.search = _.debounce(this.search, 300)
  }

  openModal() {
    this.modalTarget.classList.add("modal-open")
  }

  closeModal() {
    this.modalTarget.classList.remove("modal-open")
  }

  search() {
    this.searchFormTarget.requestSubmit()
  }
}
